{
  "compressionlevel": 0,
  "height": 15,
  "infinite": false,
  "layers": [
    {
      "id": 13,
      "layers": [
        {
          "data": [
            79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79,
            79, 79, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79,
            79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79,
            79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79,
            79, 79, 79
          ],
          "height": 15,
          "id": 17,
          "name": "CamelopardalisG7",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisG7"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Cave"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 20,
          "x": 0,
          "y": 0
        }
      ],
      "name": "Teleport",
      "opacity": 1,
      "type": "group",
      "visible": true,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 27, 28, 29, 30, 27, 28,
        29, 30, 27, 28, 29, 30, 27, 28, 29, 30, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 27, 28, 29, 30, 27, 28,
        29, 30, 27, 28, 29, 30, 27, 28, 29, 30, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 27, 28, 29, 30, 27, 28,
        29, 30, 27, 28, 29, 30, 27, 28, 29, 30, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 28,
        29, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0
      ],
      "height": 15,
      "id": 5,
      "name": "Ground",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 15,
      "id": 10,
      "name": "Stuff on the Ground You Can Walk On",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3,
        4, 5, 2, 3, 4, 5, 2, 3, 4, 5, 2, 3, 4, 5, 6, 0, 0, 11, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 0, 0, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 26, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 36, 0, 0, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 16, 0, 0, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26, 0,
        0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 36, 0, 0, 11, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 0, 0, 21, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 36, 0, 0, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 26, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 36, 0, 0, 41, 42, 43, 44, 45, 42, 43, 44, 0, 0, 43, 44, 45, 42,
        43, 44, 45, 46, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0
      ],
      "height": 15,
      "id": 6,
      "name": "Stuff You Run Into",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 67, 68, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 15,
      "id": 7,
      "name": "Stuff You Walk Under",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "draworder": "topdown",
      "id": 2,
      "name": "Objects",
      "objects": [
        {
          "height": 0,
          "id": 1,
          "name": "Default Spawn Point",
          "point": true,
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 160,
          "y": 208
        },
        {
          "height": 0,
          "id": 57,
          "name": "spawn-slime",
          "point": true,
          "rotation": 0,
          "type": "spawn-point",
          "visible": true,
          "width": 0,
          "x": 160,
          "y": 72
        },
        {
          "height": 0,
          "id": 34,
          "name": "StatisticsText",
          "point": true,
          "properties": [
            {
              "name": "Center",
              "type": "bool",
              "value": false
            },
            {
              "name": "Flavor",
              "type": "string",
              "value": "Text"
            },
            {
              "name": "Font",
              "type": "string",
              "value": "atariSunset"
            },
            {
              "name": "Size",
              "type": "int",
              "value": 12
            },
            {
              "name": "Text",
              "type": "string",
              "value": "Named\nPlayers: $namedPlayers\n\nGuests: $guests\n\nPlayers who\nFinished: $finished"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 160,
          "y": 80
        }
      ],
      "opacity": 1,
      "type": "objectgroup",
      "visible": true,
      "x": 0,
      "y": 0
    }
  ],
  "nextlayerid": 19,
  "nextobjectid": 58,
  "orientation": "orthogonal",
  "properties": [
    {
      "name": "EntranceMessage",
      "type": "string",
      "value": "I think that this cave may be broken."
    }
  ],
  "renderorder": "right-down",
  "tiledversion": "1.10.1",
  "tileheight": 16,
  "tilesets": [
    {
      "columns": 10,
      "firstgid": 1,
      "image": "../tileSets/Dungeon_Tileset-extruded.png",
      "imageheight": 180,
      "imagewidth": 180,
      "margin": 1,
      "name": "Dungeon_Tileset",
      "spacing": 2,
      "tilecount": 100,
      "tileheight": 16,
      "tilewidth": 16
    }
  ],
  "tilewidth": 16,
  "type": "map",
  "version": "1.10",
  "width": 20
}
