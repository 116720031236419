{
	"textures": [
		{
			"image": "textureAtlasOne.png",
			"format": "RGBA8888",
			"size": {
				"w": 1019,
				"h": 196
			},
			"scale": 1,
			"frames": [
				{
					"filename": "Whirl",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 720,
						"h": 45
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 720,
						"h": 45
					},
					"frame": {
						"x": 1,
						"y": 1,
						"w": 720,
						"h": 45
					}
				},
				{
					"filename": "BlackFlowingDown",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 480,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 480,
						"h": 32
					},
					"frame": {
						"x": 1,
						"y": 48,
						"w": 480,
						"h": 32
					}
				},
				{
					"filename": "quasar",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 288,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 288,
						"h": 16
					},
					"frame": {
						"x": 723,
						"y": 1,
						"w": 288,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterEastShore-sheet",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 1,
						"y": 82,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterEastShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 723,
						"y": 19,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterNorthEastShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 1,
						"y": 100,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterNorthShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 1,
						"y": 118,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterNorthWestShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 1,
						"y": 136,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterNorthWestShoreCorner",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 1,
						"y": 154,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterSouthEastShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 1,
						"y": 172,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterSouthEastShoreCorner",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 723,
						"y": 37,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "bloomby",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 240,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 240,
						"h": 16
					},
					"frame": {
						"x": 259,
						"y": 82,
						"w": 240,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterSouthShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 259,
						"y": 100,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterSouthWestShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 259,
						"y": 118,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterSouthWestShoreCorner",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 259,
						"y": 136,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterWestShore",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 259,
						"y": 154,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "PurpleRunningRunes",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 256,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 256,
						"h": 16
					},
					"frame": {
						"x": 259,
						"y": 172,
						"w": 256,
						"h": 16
					}
				},
				{
					"filename": "litSmoke",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 128,
						"h": 128
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 128,
						"h": 128
					},
					"frame": {
						"x": 517,
						"y": 48,
						"w": 128,
						"h": 128
					}
				},
				{
					"filename": "BlackWater",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 128,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 128,
						"h": 16
					},
					"frame": {
						"x": 517,
						"y": 178,
						"w": 128,
						"h": 16
					}
				},
				{
					"filename": "Fountain",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 176,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 176,
						"h": 16
					},
					"frame": {
						"x": 647,
						"y": 55,
						"w": 176,
						"h": 16
					}
				},
				{
					"filename": "laserArrow",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 16
					},
					"frame": {
						"x": 647,
						"y": 73,
						"w": 160,
						"h": 16
					}
				},
				{
					"filename": "writtenPaper",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 154,
						"h": 14
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 154,
						"h": 14
					},
					"frame": {
						"x": 825,
						"y": 55,
						"w": 154,
						"h": 14
					}
				},
				{
					"filename": "chest",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 120,
						"h": 43
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 120,
						"h": 43
					},
					"frame": {
						"x": 647,
						"y": 91,
						"w": 120,
						"h": 43
					}
				},
				{
					"filename": "MountainWallSparkleTwo",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 144,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 144,
						"h": 16
					},
					"frame": {
						"x": 825,
						"y": 71,
						"w": 144,
						"h": 16
					}
				},
				{
					"filename": "BlueWater",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 128,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 128,
						"h": 16
					},
					"frame": {
						"x": 647,
						"y": 136,
						"w": 128,
						"h": 16
					}
				},
				{
					"filename": "BlueWaterFlowingDown",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 128,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 128,
						"h": 16
					},
					"frame": {
						"x": 647,
						"y": 154,
						"w": 128,
						"h": 16
					}
				},
				{
					"filename": "LandWater",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 128,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 128,
						"h": 16
					},
					"frame": {
						"x": 647,
						"y": 172,
						"w": 128,
						"h": 16
					}
				},
				{
					"filename": "SilverSparkleForTree",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 144,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 144,
						"h": 16
					},
					"frame": {
						"x": 809,
						"y": 89,
						"w": 144,
						"h": 16
					}
				},
				{
					"filename": "WickedSnailOne",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 32,
						"h": 32
					},
					"frame": {
						"x": 483,
						"y": 48,
						"w": 32,
						"h": 32
					}
				},
				{
					"filename": "RainbowTileOne",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 128,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 128,
						"h": 16
					},
					"frame": {
						"x": 769,
						"y": 107,
						"w": 128,
						"h": 16
					}
				},
				{
					"filename": "BlackTank",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 96,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 96,
						"h": 16
					},
					"frame": {
						"x": 777,
						"y": 125,
						"w": 96,
						"h": 16
					}
				},
				{
					"filename": "BlueTank",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 96,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 96,
						"h": 16
					},
					"frame": {
						"x": 777,
						"y": 143,
						"w": 96,
						"h": 16
					}
				},
				{
					"filename": "GroundSparkleOne",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 96,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 96,
						"h": 16
					},
					"frame": {
						"x": 777,
						"y": 161,
						"w": 96,
						"h": 16
					}
				},
				{
					"filename": "GroundSparkleTwo",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 96,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 96,
						"h": 16
					},
					"frame": {
						"x": 777,
						"y": 179,
						"w": 96,
						"h": 16
					}
				},
				{
					"filename": "DarkBlueTank",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 80,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 80,
						"h": 16
					},
					"frame": {
						"x": 899,
						"y": 107,
						"w": 80,
						"h": 16
					}
				},
				{
					"filename": "PinkTank",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 96,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 96,
						"h": 16
					},
					"frame": {
						"x": 875,
						"y": 125,
						"w": 96,
						"h": 16
					}
				},
				{
					"filename": "MountainWallSparkleOne",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 80,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 80,
						"h": 16
					},
					"frame": {
						"x": 875,
						"y": 143,
						"w": 80,
						"h": 16
					}
				},
				{
					"filename": "flamingGoose",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 64,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 64,
						"h": 16
					},
					"frame": {
						"x": 875,
						"y": 161,
						"w": 64,
						"h": 16
					}
				},
				{
					"filename": "SkullWink",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 64,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 64,
						"h": 16
					},
					"frame": {
						"x": 875,
						"y": 179,
						"w": 64,
						"h": 16
					}
				},
				{
					"filename": "Barricade-BlackKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 955,
						"y": 89,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "ChairTwo",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 30,
						"h": 12
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 30,
						"h": 12
					},
					"frame": {
						"x": 981,
						"y": 19,
						"w": 30,
						"h": 12
					}
				},
				{
					"filename": "Barricade-BlueKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 981,
						"y": 33,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-DarkBlueKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 981,
						"y": 51,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-GreyKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 957,
						"y": 143,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-LightBlueKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 941,
						"y": 161,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-OrangeKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 941,
						"y": 179,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-PinkKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 959,
						"y": 161,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-PurpleKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 959,
						"y": 179,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-RedKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 999,
						"y": 33,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Barricade-TurquoiseKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 999,
						"y": 51,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Cabinet01",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 12
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 12
					},
					"frame": {
						"x": 769,
						"y": 91,
						"w": 16,
						"h": 12
					}
				},
				{
					"filename": "Cabinet04",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 12,
						"h": 15
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 12,
						"h": 15
					},
					"frame": {
						"x": 501,
						"y": 82,
						"w": 12,
						"h": 15
					}
				},
				{
					"filename": "Barricade-YellowKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 971,
						"y": 71,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "CircleTank",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 973,
						"y": 89,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "CircleTankGrey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 989,
						"y": 69,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "CircleTankOrange",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 991,
						"y": 87,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "TableOne",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 10
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 10
					},
					"frame": {
						"x": 787,
						"y": 91,
						"w": 16,
						"h": 10
					}
				},
				{
					"filename": "Cabinet03",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 12,
						"h": 12
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 12,
						"h": 12
					},
					"frame": {
						"x": 809,
						"y": 73,
						"w": 12,
						"h": 12
					}
				},
				{
					"filename": "CircleTankRed",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 973,
						"y": 125,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "corpse",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 981,
						"y": 107,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "LetterM",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 975,
						"y": 143,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "PurpleTank",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 977,
						"y": 161,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "TankTurquoise",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 977,
						"y": 179,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "YellowTank",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 16
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 16
					},
					"frame": {
						"x": 991,
						"y": 125,
						"w": 16,
						"h": 16
					}
				},
				{
					"filename": "Cabinet02",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 15
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 15
					},
					"frame": {
						"x": 993,
						"y": 143,
						"w": 16,
						"h": 15
					}
				},
				{
					"filename": "Cabinet05",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 16,
						"h": 15
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 16,
						"h": 15
					},
					"frame": {
						"x": 995,
						"y": 160,
						"w": 16,
						"h": 15
					}
				},
				{
					"filename": "Cabinet06",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 12,
						"h": 15
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 12,
						"h": 15
					},
					"frame": {
						"x": 995,
						"y": 177,
						"w": 12,
						"h": 15
					}
				},
				{
					"filename": "BlackKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 177,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "BlueKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 185,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "DarkBlueKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1007,
						"y": 69,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "GreyKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1007,
						"y": 77,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "LightBlueKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 85,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "OrangeKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 93,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "PinkKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 101,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "PurpleKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 109,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "RedKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 999,
						"y": 117,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "TurquoiseKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 125,
						"w": 9,
						"h": 6
					}
				},
				{
					"filename": "YellowKey",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 9,
						"h": 6
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 9,
						"h": 6
					},
					"frame": {
						"x": 1009,
						"y": 133,
						"w": 9,
						"h": 6
					}
				}
			]
		}
	],
	"meta": {
		"app": "https://www.codeandweb.com/texturepacker",
		"version": "3.0",
		"smartupdate": "$TexturePacker:SmartUpdate:0e6182e91b495d89264127c3418a0e51:978fc28d96340a9e2dcb8aaf418a850a:01bd61073ce0ee21c5bb2be67683edc1$"
	}
}
