export default {
  quasar: {
    sprite: 'quasar',
    velocity: 150,
    type: 'damage',
    fly: true,
  },
  laserArrow: {
    sprite: 'laserArrow',
    velocity: 150,
    type: 'damage',
    fly: true,
  },
};
