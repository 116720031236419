{
  "compressionlevel": 0,
  "height": 26,
  "infinite": false,
  "layers": [
    {
      "id": 13,
      "layers": [
        {
          "data": [
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          "height": 26,
          "id": 14,
          "name": "WestTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisI4"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Right"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12
          ],
          "height": 26,
          "id": 16,
          "name": "EastTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisK4"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Left"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 0, 0, 0, 0, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          "height": 26,
          "id": 15,
          "name": "NorthTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisJ3"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Lower"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 0, 0, 0, 0, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 0, 0
          ],
          "height": 26,
          "id": 17,
          "name": "SouthTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisJ5"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Upper"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        }
      ],
      "name": "Teleport",
      "opacity": 1,
      "type": "group",
      "visible": true,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0,
        0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0,
        0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 5,
      "name": "Ground",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        97, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98,
        98, 99, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 109, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110,
        110, 110, 110, 110, 110, 110, 110, 111, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 109, 110, 110, 110, 110, 110,
        110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 111, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        109, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110,
        110, 110, 110, 110, 110, 111, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 109, 110, 110, 110, 110, 110, 110, 110,
        110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 111, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 109, 110,
        110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110,
        110, 110, 110, 111, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 109, 110, 110, 110, 110, 110, 110, 110, 110, 110,
        110, 110, 110, 110, 110, 110, 110, 110, 110, 111, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 121, 122, 122, 122,
        122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
        122, 123, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0
      ],
      "height": 26,
      "id": 12,
      "name": "Water",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 10,
      "name": "Stuff on the Ground You Can Walk On",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 0, 0, 0, 0, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 0, 0, 0, 0, 81, 82, 83, 84, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 80,
        81, 82, 0, 0, 0, 0, 93, 94, 95, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 92, 93,
        94, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 0,
        0, 0, 0, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 93, 94, 0, 0, 0,
        0, 81, 82, 0, 0, 0, 0, 27, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 27, 28, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0,
        93, 94, 0, 0, 0, 0, 39, 40, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 39, 40, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 81,
        82, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 93, 94, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0,
        27, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 27, 28, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 39,
        40, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 39, 40, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 93, 94, 0, 0, 0, 0, 81, 82, 59, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56,
        81, 82, 0, 0, 0, 0, 93, 94, 71, 72, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 68, 93,
        94, 0, 0, 0, 0, 81, 82, 81, 82, 57, 58, 57, 58, 57, 58, 57, 58, 59, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56, 57, 58, 57, 58, 57, 58, 57,
        58, 81, 82, 81, 82, 0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94,
        93, 94, 71, 72, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 68, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81,
        82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 0, 0, 0, 0, 93, 94,
        93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 6,
      "name": "Stuff You Run Into",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 7,
      "name": "Stuff You Walk Under",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "draworder": "topdown",
      "id": 2,
      "name": "Objects",
      "objects": [
        {
          "height": 0,
          "id": 25,
          "name": "Default Spawn Point",
          "point": true,
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 352,
          "y": 376
        },
        {
          "height": 0,
          "id": 34,
          "name": "Lower",
          "point": true,
          "properties": [
            {
              "name": "Type",
              "type": "string",
              "value": "Entrance"
            },
            {
              "name": "allowCustomX",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 352,
          "y": 376
        },
        {
          "height": 0,
          "id": 61,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 1
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 560,
          "y": 120
        },
        {
          "height": 0,
          "id": 64,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 0
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 144,
          "y": 120
        },
        {
          "height": 0,
          "id": 66,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 2
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 608,
          "y": 160
        },
        {
          "height": 0,
          "id": 67,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 3
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 608,
          "y": 256
        },
        {
          "height": 0,
          "id": 68,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 4
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 560,
          "y": 296
        },
        {
          "height": 0,
          "id": 69,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 5
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 144,
          "y": 296
        },
        {
          "height": 0,
          "id": 70,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 6
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 96,
          "y": 256
        },
        {
          "height": 0,
          "id": 72,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 7
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 96,
          "y": 160
        },
        {
          "height": 0,
          "id": 73,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385001"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 111.5,
          "y": 120.25
        },
        {
          "height": 0,
          "id": 74,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385002"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 181,
          "y": 120
        },
        {
          "height": 0,
          "id": 75,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385003"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 234,
          "y": 120
        },
        {
          "height": 0,
          "id": 76,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385004"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 281,
          "y": 120
        },
        {
          "height": 0,
          "id": 77,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385005"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 325.25,
          "y": 120
        },
        {
          "height": 0,
          "id": 78,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385006"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 365,
          "y": 120
        },
        {
          "height": 0,
          "id": 79,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385007"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 412.5,
          "y": 120
        },
        {
          "height": 0,
          "id": 80,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385008"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 455,
          "y": 120
        },
        {
          "height": 0,
          "id": 81,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385009"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 497.25,
          "y": 120
        },
        {
          "height": 0,
          "id": 82,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385010"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 1
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 535.75,
          "y": 120
        },
        {
          "height": 0,
          "id": 83,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385011"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 45
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 580.25,
          "y": 133.25
        },
        {
          "height": 0,
          "id": 84,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385012"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 45
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 594.5,
          "y": 145
        },
        {
          "height": 0,
          "id": 85,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385013"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 3
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 608,
          "y": 178.75
        },
        {
          "height": 0,
          "id": 86,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385014"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 3
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 608,
          "y": 202.75
        },
        {
          "height": 0,
          "id": 87,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385015"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 3
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 608,
          "y": 227.25
        },
        {
          "height": 0,
          "id": 88,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385017"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 135
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 594,
          "y": 270
        },
        {
          "height": 0,
          "id": 89,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385017"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 135
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 576.25,
          "y": 283.5
        },
        {
          "height": 0,
          "id": 90,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385018"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 524.5,
          "y": 295
        },
        {
          "height": 0,
          "id": 91,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385019"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 489.25,
          "y": 296
        },
        {
          "height": 0,
          "id": 92,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385020"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 454.75,
          "y": 296
        },
        {
          "height": 0,
          "id": 93,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385021"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 415.25,
          "y": 296
        },
        {
          "height": 0,
          "id": 94,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385022"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 385.75,
          "y": 296
        },
        {
          "height": 0,
          "id": 95,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385023"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 345.75,
          "y": 296
        },
        {
          "height": 0,
          "id": 96,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385024"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 310.75,
          "y": 296
        },
        {
          "height": 0,
          "id": 97,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385025"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 273.75,
          "y": 296
        },
        {
          "height": 0,
          "id": 98,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385026"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 239.25,
          "y": 296
        },
        {
          "height": 0,
          "id": 99,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385027"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 198.5,
          "y": 296
        },
        {
          "height": 0,
          "id": 100,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385028"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 5
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 180
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 172.5,
          "y": 296
        },
        {
          "height": 0,
          "id": 101,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385029"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 225
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 125.25,
          "y": 281
        },
        {
          "height": 0,
          "id": 102,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385030"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 225
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 105,
          "y": 266.25
        },
        {
          "height": 0,
          "id": 103,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385031"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 7
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 96,
          "y": 225.5
        },
        {
          "height": 0,
          "id": 104,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385032"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 7
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 96,
          "y": 199
        },
        {
          "height": 0,
          "id": 105,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385033"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 7
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 96,
          "y": 173.75
        },
        {
          "height": 0,
          "id": 106,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 1
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385034"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 315
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 125.5,
          "y": 138.5
        },

        {
          "height": 0,
          "id": 107,
          "name": "Orange Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "bool",
              "value": true
            },
            {
              "name": "destination01",
              "type": "string",
              "value": "560,120"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "followPath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "59afd40a-801e-4b44-9f83-3c7d73385035"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 100
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "circle"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 30
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "CircleTankOrange"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "int",
              "value": 160
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 109.5,
          "y": 146.75
        },
        {
          "height": 0,
          "id": 108,
          "name": "OrangeKey",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Item"
            },
            {
              "name": "Function",
              "type": "string",
              "value": "allNPCsOff"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "id",
              "type": "string",
              "value": "b72b5efa-739a-451e-a01c-a789e448998c"
            },
            {
              "name": "iin",
              "type": "string",
              "value": "Orange Key"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "OrangeKey"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 352,
          "y": 127
        }
      ],
      "opacity": 1,
      "type": "objectgroup",
      "visible": true,
      "x": 0,
      "y": 0
    }
  ],
  "nextlayerid": 13,
  "nextobjectid": 109,
  "orientation": "orthogonal",
  "properties": [
    {
      "name": "EntranceMessage",
      "type": "string",
      "value": "The ekpyrotic process begins far in the indefinite past"
    }
  ],
  "renderorder": "right-down",
  "tiledversion": "1.10.0",
  "tileheight": 16,
  "tilesets": [
    {
      "columns": 12,
      "firstgid": 1,
      "image": "../tileSets/CamelopardalisColor16x16-extruded.png",
      "imageheight": 286,
      "imagewidth": 229,
      "margin": 2,
      "name": "CamelopardalisColor16x16",
      "spacing": 3,
      "tilecount": 180,
      "tileheight": 16,
      "tilewidth": 16
    }
  ],
  "tilewidth": 16,
  "type": "map",
  "version": "1.10",
  "width": 44
}
