{
  "compressionlevel": 0,
  "height": 26,
  "infinite": false,
  "layers": [
    {
      "id": 13,
      "layers": [
        {
          "data": [
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          "height": 26,
          "id": 14,
          "name": "WestTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisI1"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Right"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12
          ],
          "height": 26,
          "id": 16,
          "name": "EastTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisK1"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Left"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 0, 0, 0, 0, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          "height": 26,
          "id": 12,
          "name": "NorthTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "EmptyCave"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Default"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 0, 0, 0, 0, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 0, 0
          ],
          "height": 26,
          "id": 17,
          "name": "SouthTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisJ2"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Upper"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        }
      ],
      "name": "Teleport",
      "opacity": 1,
      "type": "group",
      "visible": true,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0,
        0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0,
        0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 5,
      "name": "Ground",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 10,
      "name": "Stuff on the Ground You Can Walk On",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 0, 0, 0, 0, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 0, 0, 0, 0, 81, 82, 83, 84, 79, 80, 83, 84, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 80,
        81, 82, 81, 82, 0, 0, 0, 0, 93, 94, 95, 0, 0, 92, 95, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 92,
        93, 94, 93, 94, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79,
        80, 81, 82, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 92,
        93, 94, 0, 0, 0, 0, 83, 84, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 80,
        0, 0, 0, 0, 95, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 92, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 59, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 56, 0, 0, 0, 0, 71, 72, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 67, 68, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56,
        81, 82, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 68, 93, 94,
        0, 0, 0, 0, 81, 82, 59, 0, 0, 56, 59, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56, 81, 82, 81, 82,
        0, 0, 0, 0, 93, 94, 71, 72, 67, 68, 71, 72, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 68, 93, 94, 93,
        94, 0, 0, 0, 0, 81, 82, 81, 82, 81, 82, 81, 82, 57, 58, 57, 58, 57, 58,
        57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58,
        57, 58, 81, 82, 81, 82, 81, 82, 0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 6,
      "name": "Stuff You Run Into",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 7,
      "name": "Stuff You Walk Under",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "draworder": "topdown",
      "id": 2,
      "name": "Objects",
      "objects": [
        {
          "height": 0,
          "id": 25,
          "name": "Default Spawn Point",
          "point": true,
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 352,
          "y": 208
        },
        {
          "height": 0,
          "id": 26,
          "name": "Left",
          "point": true,
          "properties": [
            {
              "name": "Type",
              "type": "string",
              "value": "Entrance"
            },
            {
              "name": "allowCustomY",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 208
        },
        {
          "height": 0,
          "id": 37,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "5ccc9d1b-aa8a-4eed-a4c3-05b1c2403898"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 95,
          "y": 293.5
        },
        {
          "height": 0,
          "id": 38,
          "name": "DarkBlueKey",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Item"
            },
            {
              "name": "Function",
              "type": "string",
              "value": "allNPCsOff"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "id",
              "type": "string",
              "value": "56a366f1-fd14-4425-8bb4-cb5c8d821dfd"
            },
            {
              "name": "iin",
              "type": "string",
              "value": "Dark Blue Key"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueKey"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 628.5,
          "y": 207.5
        },
        {
          "height": 0,
          "id": 39,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "135eaabb-122c-41e6-ab92-69875ef1f541"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 95.5,
          "y": 122.5
        },
        {
          "height": 0,
          "id": 40,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "0a17de39-dcdd-46bc-bc4f-a4b027fcb158"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 367.5,
          "y": 111.5
        },
        {
          "height": 0,
          "id": 41,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "418d64ef-7350-4a2b-afef-90c20d363a18"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 304,
          "y": 112
        },
        {
          "height": 0,
          "id": 42,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "c3d87091-10fb-4602-bf6c-475b776e223a"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 239.5,
          "y": 111.5
        },
        {
          "height": 0,
          "id": 43,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "6e233ea0-6a6d-402a-85c5-3fb41626d9c2"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 175.5,
          "y": 112
        },
        {
          "height": 0,
          "id": 44,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "a549e278-d44a-4847-807d-07d1c0a9d139"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 447.5,
          "y": 112.5
        },
        {
          "height": 0,
          "id": 45,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "e8630a75-4af6-48c5-aa52-6365603348f0"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 90
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 527.5,
          "y": 112.5
        },
        {
          "height": 0,
          "id": 46,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "738f0868-2bba-4497-ae5d-6c39fbb9f254"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 368,
          "y": 304.5
        },
        {
          "height": 0,
          "id": 47,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "8144d02b-7199-4af2-869a-cebb2f936786"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 304.5,
          "y": 305
        },
        {
          "height": 0,
          "id": 48,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "1ee6932b-fc54-4f14-8b32-6d2e76e64167"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 240,
          "y": 304.5
        },
        {
          "height": 0,
          "id": 49,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "3fc03a3a-a23e-438b-b70a-d8855d9292d8"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 176,
          "y": 305
        },
        {
          "height": 0,
          "id": 50,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "1545d3ff-0597-4bad-a0bb-9b24f6e17fe4"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 448,
          "y": 305.5
        },
        {
          "height": 0,
          "id": 51,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "a694e6fc-4904-4592-927b-020d832c3a0a"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 225
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 591.5,
          "y": 272
        },
        {
          "height": 0,
          "id": 52,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "d95d0fc9-1455-4d1f-821f-1af084f67849"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 529.5,
          "y": 302
        },
        {
          "height": 0,
          "id": 53,
          "name": "Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "dps",
              "type": "int",
              "value": 10
            },
            {
              "name": "faceTarget",
              "type": "bool",
              "value": true
            },
            {
              "name": "health",
              "type": "int",
              "value": 20
            },
            {
              "name": "id",
              "type": "string",
              "value": "006ea142-8993-4b90-b287-50634ea29971"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 135
            },
            {
              "name": "nearbyObjectDetection",
              "type": "int",
              "value": 25
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "rateOfFire",
              "type": "int",
              "value": 250
            },
            {
              "name": "rayCast",
              "type": "bool",
              "value": true
            },
            {
              "name": "rayCastDegrees",
              "type": "int",
              "value": 180
            },
            {
              "name": "rayCastDistance",
              "type": "int",
              "value": 200
            },
            {
              "name": "rayCastType",
              "type": "string",
              "value": "cone"
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 300
            },
            {
              "name": "spell",
              "type": "string",
              "value": "laserArrow"
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "DarkBlueTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 591.5,
          "y": 143
        },
        {
          "height": 0,
          "id": 54,
          "name": "Right",
          "point": true,
          "properties": [
            {
              "name": "Type",
              "type": "string",
              "value": "Entrance"
            },
            {
              "name": "allowCustomY",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 664,
          "y": 208.5
        }
      ],
      "opacity": 1,
      "type": "objectgroup",
      "visible": true,
      "x": 0,
      "y": 0
    }
  ],
  "nextlayerid": 16,
  "nextobjectid": 55,
  "orientation": "orthogonal",
  "renderorder": "right-down",
  "tiledversion": "1.10.1",
  "tileheight": 16,
  "tilesets": [
    {
      "columns": 12,
      "firstgid": 1,
      "image": "../tileSets/CamelopardalisColor16x16-extruded.png",
      "imageheight": 286,
      "imagewidth": 229,
      "margin": 2,
      "name": "CamelopardalisColor16x16",
      "spacing": 3,
      "tilecount": 180,
      "tileheight": 16,
      "tilewidth": 16
    }
  ],
  "tilewidth": 16,
  "type": "map",
  "version": "1.10",
  "width": 44
}
