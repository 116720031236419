{
  "compressionlevel": 0,
  "height": 26,
  "infinite": false,
  "layers": [
    {
      "id": 13,
      "layers": [
        {
          "data": [
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          "height": 26,
          "id": 14,
          "name": "WestTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisD1"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Right"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12
          ],
          "height": 26,
          "id": 16,
          "name": "EastTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisF1"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Left"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 0, 0, 0, 0, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          "height": 26,
          "id": 12,
          "name": "NorthTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "EmptyCave"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Door"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 0, 0, 0, 0, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
            12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 0, 0
          ],
          "height": 26,
          "id": 17,
          "name": "SouthTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CamelopardalisE2"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Upper"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        },
        {
          "data": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 12, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          "height": 26,
          "id": 18,
          "name": "DoorTeleportLayer",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "EmptyCave"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 44,
          "x": 0,
          "y": 0
        }
      ],
      "name": "Teleport",
      "opacity": 1,
      "type": "group",
      "visible": true,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0,
        0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0,
        0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1,
        2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 0, 0, 0, 0,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
        1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0, 0, 0, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13, 14, 13,
        14, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 5,
      "name": "Ground",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 35, 36, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 47, 48, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 10,
      "name": "Stuff on the Ground You Can Walk On",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 0, 0, 81, 82, 81, 82, 81, 82, 0, 0, 0, 0, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 0, 0, 93, 94, 93,
        94, 93, 94, 0, 0, 0, 0, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 83, 84,
        0, 0, 0, 0, 0, 0, 0, 0, 79, 80, 0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93,
        94, 93, 94, 95, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 92, 0, 0, 0, 0, 81, 82,
        81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 81, 82, 83, 84, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94,
        93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 95, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82,
        81, 82, 81, 82, 83, 84, 79, 80, 81, 82, 81, 82, 83, 84, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94,
        93, 94, 93, 94, 93, 94, 93, 94, 95, 0, 0, 92, 93, 94, 93, 94, 95, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 83, 84, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 80, 83, 84, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 93, 94, 95, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 92, 95, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 81, 82, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 81, 82, 59, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56,
        59, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56, 0, 0, 0, 0, 93,
        94, 71, 72, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 68, 71,
        72, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 68, 0, 0, 0, 0, 81,
        82, 81, 82, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57,
        58, 81, 82, 81, 82, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57, 58, 57,
        58, 81, 82, 0, 0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94,
        93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94,
        93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 81, 82, 81, 82, 81,
        82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81,
        82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 81, 82, 0,
        0, 0, 0, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94,
        93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94, 93, 94,
        93, 94, 93, 94, 93, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0
      ],
      "height": 26,
      "id": 6,
      "name": "Stuff You Run Into",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 26,
      "id": 7,
      "name": "Stuff You Walk Under",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 44,
      "x": 0,
      "y": 0
    },
    {
      "draworder": "topdown",
      "id": 2,
      "name": "Objects",
      "objects": [
        {
          "height": 0,
          "id": 25,
          "name": "Default Spawn Point",
          "point": true,
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 384,
          "y": 256
        },
        {
          "height": 0,
          "id": 28,
          "name": "Right",
          "point": true,
          "properties": [
            {
              "name": "Type",
              "type": "string",
              "value": "Entrance"
            },
            {
              "name": "allowCustomY",
              "type": "bool",
              "value": true
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 664,
          "y": 208
        },
        {
          "height": 0,
          "id": 36,
          "name": "Cave",
          "point": true,
          "properties": [
            {
              "name": "Type",
              "type": "string",
              "value": "Entrance"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 560,
          "y": 96
        },
        {
          "height": 0,
          "id": 38,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 0
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 107.875,
          "y": 226.875
        },
        {
          "height": 0,
          "id": 39,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 1
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 301.5833,
          "y": 312.083
        },
        {
          "height": 0,
          "id": 40,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 2
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 255.125,
          "y": 216.208
        },
        {
          "height": 0,
          "id": 41,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 3
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 623.25,
          "y": 310.292
        },
        {
          "height": 0,
          "id": 42,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 4
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 624.5,
          "y": 128.5
        },
        {
          "height": 0,
          "id": 43,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 5
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 542.7083,
          "y": 205.667
        },
        {
          "height": 0,
          "id": 44,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 6
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 522.083,
          "y": 150.833
        },
        {
          "height": 0,
          "id": 45,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 7
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 643.292,
          "y": 287.375
        },
        {
          "height": 0,
          "id": 46,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 8
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 75.6667,
          "y": 257.667
        },
        {
          "height": 0,
          "id": 47,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "d321c508-75cc-4856-8d2e-d7b88b0bf57e"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 0
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 219.667,
          "y": 249.125
        },
        {
          "height": 0,
          "id": 48,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "d7dac56f-1718-4fec-9ae8-9396ff8e89f3"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 237.625,
          "y": 269.375
        },
        {
          "height": 0,
          "id": 49,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "e84fbb35-efd7-43a0-8dfc-e741ea9d2363"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 219.5,
          "y": 283.75
        },
        {
          "height": 0,
          "id": 50,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "5e530c75-a29e-4a2c-a992-5240853025b9"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 205.125,
          "y": 269
        },
        {
          "height": 0,
          "id": 51,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "6969f013-0455-4a48-b6ea-80e210744926"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 8
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 188.625,
          "y": 255.375
        },
        {
          "height": 0,
          "id": 52,
          "name": "BlackKey",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Item"
            },
            {
              "name": "Function",
              "type": "string",
              "value": "allNPCsOff"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "id",
              "type": "string",
              "value": "ef26a8b0-852b-47f0-a645-8417871467d7"
            },
            {
              "name": "iin",
              "type": "string",
              "value": "Black Key"
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 270
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackKey"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 320,
          "y": 185
        },
        {
          "height": 0,
          "id": 53,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "1c3adb31-eb3d-4354-bd5f-4efd577921c8"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 0
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 477.042,
          "y": 222.75
        },
        {
          "height": 0,
          "id": 54,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "6c8bdcc0-6f5c-4c54-b6ef-9692f490c732"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 495,
          "y": 243
        },
        {
          "height": 0,
          "id": 55,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "66b99100-f795-488d-9594-9063836d735b"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 476.875,
          "y": 257.375
        },
        {
          "height": 0,
          "id": 56,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "09893366-a0ba-49bb-9694-bbca8b48f049"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 462.5,
          "y": 242.625
        },
        {
          "height": 0,
          "id": 57,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "d770c5cb-5644-4191-9523-850b015cdca5"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 8
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 446,
          "y": 229
        },
        {
          "height": 0,
          "id": 58,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "010520f5-719e-4dcc-9e42-bae6451f0c55"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 0
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 591.042,
          "y": 149.25
        },
        {
          "height": 0,
          "id": 59,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "f95fc662-82fc-46b1-9300-f3740f3db4c1"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 609,
          "y": 169.5
        },
        {
          "height": 0,
          "id": 60,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "e20f82a7-5075-4a42-9eb6-03ae4459bd8a"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 590.875,
          "y": 183.875
        },
        {
          "height": 0,
          "id": 61,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "0eda1252-f8b4-4ed7-a6f0-e7a5f92e6d29"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 576.5,
          "y": 169.125
        },
        {
          "height": 0,
          "id": 62,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "49fd3459-68eb-4393-aefc-876a74b0ceb9"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 8
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 560,
          "y": 155.5
        },
        {
          "height": 0,
          "id": 63,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "62b9a1ff-9695-4745-a477-ad829b7743b3"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 0
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 589.542,
          "y": 250.25
        },
        {
          "height": 0,
          "id": 64,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "d6b5e765-0afb-463e-8ebf-d6e9782eebdc"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 607.5,
          "y": 270.5
        },
        {
          "height": 0,
          "id": 65,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "f49dc0d7-c751-483f-a380-c67cb20bd295"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 589.375,
          "y": 284.875
        },
        {
          "height": 0,
          "id": 66,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "c986b95e-2dc4-4b3e-88b1-cc83bea87053"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 575,
          "y": 270.125
        },
        {
          "height": 0,
          "id": 67,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "ceaa483a-a0a1-40dd-aeec-fdba5d713854"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 8
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 558.5,
          "y": 256.5
        },
        {
          "height": 0,
          "id": 68,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "dc8c809d-82e4-41f1-88a5-60c4bbcac647"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 0
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 329.042,
          "y": 250.75
        },
        {
          "height": 0,
          "id": 69,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "072257cb-a490-43ff-8326-aea0174b30db"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 347,
          "y": 271
        },
        {
          "height": 0,
          "id": 70,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "0a0462e8-545b-4eb7-98c2-dbc76fd7ebe9"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 328.875,
          "y": 285.375
        },
        {
          "height": 0,
          "id": 71,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "d267c42c-7288-4f36-867f-0ed9734ae57b"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 314.5,
          "y": 270.625
        },
        {
          "height": 0,
          "id": 72,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "26614717-7e90-44b2-937c-b996d71e0480"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 8
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 298,
          "y": 257
        },
        {
          "height": 0,
          "id": 73,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "d1b3bab9-c8cb-4ba6-b5e9-1c825fdc1df4"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 0
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 133.542,
          "y": 252.75
        },
        {
          "height": 0,
          "id": 74,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "609d74f7-e874-4d10-a8cd-3b8ac7619576"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 2
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 151.5,
          "y": 273
        },
        {
          "height": 0,
          "id": 75,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "31fa7a77-9dd2-44eb-aebe-ff7bc0982e1b"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 6
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 133.375,
          "y": 287.375
        },
        {
          "height": 0,
          "id": 76,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "53346e56-d464-486f-8391-d73b05f85656"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 4
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 119,
          "y": 272.625
        },
        {
          "height": 0,
          "id": 77,
          "name": "Black Tank",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NPC"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Quark"
            },
            {
              "name": "damageOnContact",
              "type": "int",
              "value": 1
            },
            {
              "name": "dod",
              "type": "bool",
              "value": false
            },
            {
              "name": "health",
              "type": "int",
              "value": 10
            },
            {
              "name": "id",
              "type": "string",
              "value": "2db69d52-7366-4369-afb5-ce5b67357fb5"
            },
            {
              "name": "initialPathWaypoint",
              "type": "int",
              "value": 8
            },
            {
              "name": "initialSpriteDirection",
              "type": "int",
              "value": 0
            },
            {
              "name": "navigatePath",
              "type": "string",
              "value": "One"
            },
            {
              "name": "pod",
              "type": "bool",
              "value": true
            },
            {
              "name": "respawnInSeconds",
              "type": "int",
              "value": 60
            },
            {
              "name": "sprite",
              "type": "string",
              "value": "BlackTank"
            },
            {
              "name": "tcw",
              "type": "bool",
              "value": true
            },
            {
              "name": "velocity",
              "type": "string",
              "value": "150"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 102.5,
          "y": 259
        },
        {
          "height": 0,
          "id": 78,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 9
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 561,
          "y": 106
        },

        {
          "height": 0,
          "id": 79,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 10
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 334.5,
          "y": 304.5
        },
        {
          "height": 0,
          "id": 80,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 11
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 323,
          "y": 311
        },
        {
          "height": 0,
          "id": 81,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 12
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 109,
          "y": 309
        },
        {
          "height": 0,
          "id": 82,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 13
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 315,
          "y": 185
        },
        {
          "height": 0,
          "id": 83,
          "name": "Waypoint",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "Waypoint"
            },
            {
              "name": "Path",
              "type": "string",
              "value": "One"
            },
            {
              "name": "Progression",
              "type": "int",
              "value": 14
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 107.5,
          "y": 207.5
        }
      ],
      "opacity": 1,
      "type": "objectgroup",
      "visible": true,
      "x": 0,
      "y": 0
    }
  ],
  "nextlayerid": 19,
  "nextobjectid": 84,
  "orientation": "orthogonal",
  "renderorder": "right-down",
  "tiledversion": "1.10.1",
  "tileheight": 16,
  "tilesets": [
    {
      "columns": 12,
      "firstgid": 1,
      "image": "../tileSets/CamelopardalisColor16x16-extruded.png",
      "imageheight": 286,
      "imagewidth": 229,
      "margin": 2,
      "name": "CamelopardalisColor16x16",
      "spacing": 3,
      "tilecount": 180,
      "tileheight": 16,
      "tilewidth": 16
    }
  ],
  "tilewidth": 16,
  "type": "map",
  "version": "1.10",
  "width": 44
}
