{
  "compressionlevel": 0,
  "height": 15,
  "infinite": false,
  "layers": [
    {
      "id": 13,
      "layers": [
        {
          "data": [
            79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79,
            79, 79, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79,
            79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 79, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79,
            79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79,
            79, 79, 79
          ],
          "height": 15,
          "id": 17,
          "name": "CamelopardalisH8",
          "opacity": 1,
          "properties": [
            {
              "name": "DestinationScene",
              "type": "string",
              "value": "CaveExit"
            },
            {
              "name": "Entrance",
              "type": "string",
              "value": "Cave"
            }
          ],
          "type": "tilelayer",
          "visible": true,
          "width": 20,
          "x": 0,
          "y": 0
        }
      ],
      "name": "Teleport",
      "opacity": 1,
      "type": "group",
      "visible": true,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 27, 28, 29, 30, 27, 28,
        29, 30, 27, 28, 29, 30, 27, 28, 29, 30, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 27, 28, 29, 30, 27, 28,
        29, 30, 27, 28, 29, 30, 27, 28, 29, 30, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 27, 28, 29, 30, 27, 28,
        29, 30, 27, 28, 29, 30, 27, 28, 29, 30, 0, 0, 0, 0, 7, 8, 9, 10, 7, 8,
        9, 10, 7, 8, 9, 10, 7, 8, 9, 10, 0, 0, 0, 0, 17, 18, 19, 20, 17, 18, 19,
        20, 17, 18, 19, 20, 17, 18, 19, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 28,
        29, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0
      ],
      "height": 15,
      "id": 5,
      "name": "Ground",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 15,
      "id": 10,
      "name": "Stuff on the Ground You Can Walk On",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3,
        4, 5, 2, 3, 4, 5, 2, 3, 4, 5, 2, 3, 4, 5, 6, 0, 0, 11, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 0, 0, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 26, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 36, 0, 0, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 16, 0, 0, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26, 0,
        0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 36, 0, 0, 11, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 0, 0, 21, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 36, 0, 0, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 26, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 36, 0, 0, 41, 42, 43, 44, 45, 42, 43, 44, 0, 0, 43, 44, 45, 42,
        43, 44, 45, 46, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0
      ],
      "height": 15,
      "id": 6,
      "name": "Stuff You Run Into",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 67, 68, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      "height": 15,
      "id": 7,
      "name": "Stuff You Walk Under",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "draworder": "topdown",
      "id": 2,
      "name": "Objects",
      "objects": [
        {
          "height": 0,
          "id": 1,
          "name": "HealingSpawnPoint",
          "point": true,
          "properties": [
            {
              "name": "Type",
              "type": "string",
              "value": "Entrance"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 160,
          "y": 100
        },
        {
          "height": 0,
          "id": 57,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 40
        },
        {
          "height": 0,
          "id": 58,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 68
        },
        {
          "height": 0,
          "id": 59,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 52
        },
        {
          "height": 0,
          "id": 60,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 84
        },
        {
          "height": 0,
          "id": 61,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 100
        },
        {
          "height": 0,
          "id": 62,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 128
        },
        {
          "height": 0,
          "id": 63,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 112
        },
        {
          "height": 0,
          "id": 64,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 144
        },
        {
          "height": 0,
          "id": 65,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 160
        },
        {
          "height": 0,
          "id": 66,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 188
        },
        {
          "height": 0,
          "id": 67,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 172
        },
        {
          "height": 0,
          "id": 68,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 40,
          "y": 204
        },
        {
          "height": 0,
          "id": 79,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 40
        },
        {
          "height": 0,
          "id": 80,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 68
        },
        {
          "height": 0,
          "id": 81,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 52
        },
        {
          "height": 0,
          "id": 82,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 84
        },
        {
          "height": 0,
          "id": 83,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 100
        },
        {
          "height": 0,
          "id": 84,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 128
        },
        {
          "height": 0,
          "id": 85,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 112
        },

        {
          "height": 0,
          "id": 86,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 144
        },
        {
          "height": 0,
          "id": 87,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 160
        },
        {
          "height": 0,
          "id": 88,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 188
        },
        {
          "height": 0,
          "id": 89,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 172
        },
        {
          "height": 0,
          "id": 90,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 56,
          "y": 204
        },
        {
          "height": 0,
          "id": 91,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 40
        },
        {
          "height": 0,
          "id": 92,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 68
        },
        {
          "height": 0,
          "id": 93,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 52
        },
        {
          "height": 0,
          "id": 94,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 84
        },
        {
          "height": 0,
          "id": 95,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 100
        },
        {
          "height": 0,
          "id": 96,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 128
        },
        {
          "height": 0,
          "id": 97,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 112
        },
        {
          "height": 0,
          "id": 98,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 144
        },
        {
          "height": 0,
          "id": 99,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 160
        },
        {
          "height": 0,
          "id": 100,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 188
        },
        {
          "height": 0,
          "id": 101,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 172
        },
        {
          "height": 0,
          "id": 102,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 72,
          "y": 204
        },
        {
          "height": 0,
          "id": 115,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 40
        },
        {
          "height": 0,
          "id": 116,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 68
        },
        {
          "height": 0,
          "id": 117,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 52
        },

        {
          "height": 0,
          "id": 118,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 84
        },
        {
          "height": 0,
          "id": 119,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 100
        },
        {
          "height": 0,
          "id": 120,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 128
        },
        {
          "height": 0,
          "id": 121,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 112
        },
        {
          "height": 0,
          "id": 122,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 144
        },
        {
          "height": 0,
          "id": 123,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 160
        },
        {
          "height": 0,
          "id": 124,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 188
        },
        {
          "height": 0,
          "id": 125,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 172
        },
        {
          "height": 0,
          "id": 126,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 88,
          "y": 204
        },
        {
          "height": 0,
          "id": 127,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 40
        },
        {
          "height": 0,
          "id": 128,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 68
        },
        {
          "height": 0,
          "id": 129,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 52
        },
        {
          "height": 0,
          "id": 130,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 84
        },
        {
          "height": 0,
          "id": 131,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 100
        },
        {
          "height": 0,
          "id": 132,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 128
        },
        {
          "height": 0,
          "id": 133,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 112
        },
        {
          "height": 0,
          "id": 134,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 144
        },
        {
          "height": 0,
          "id": 135,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 160
        },
        {
          "height": 0,
          "id": 136,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 188
        },
        {
          "height": 0,
          "id": 137,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 172
        },

        {
          "height": 0,
          "id": 138,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 104,
          "y": 204
        },
        {
          "height": 0,
          "id": 139,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 40
        },
        {
          "height": 0,
          "id": 140,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 68
        },
        {
          "height": 0,
          "id": 141,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 52
        },
        {
          "height": 0,
          "id": 142,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 84
        },
        {
          "height": 0,
          "id": 143,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 100
        },
        {
          "height": 0,
          "id": 144,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 128
        },
        {
          "height": 0,
          "id": 145,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 112
        },
        {
          "height": 0,
          "id": 146,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 144
        },
        {
          "height": 0,
          "id": 147,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 160
        },
        {
          "height": 0,
          "id": 148,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 188
        },
        {
          "height": 0,
          "id": 149,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 172
        },
        {
          "height": 0,
          "id": 150,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 120,
          "y": 204
        },
        {
          "height": 0,
          "id": 151,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 40
        },
        {
          "height": 0,
          "id": 152,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 68
        },
        {
          "height": 0,
          "id": 153,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 52
        },
        {
          "height": 0,
          "id": 154,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 84
        },
        {
          "height": 0,
          "id": 155,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 100
        },
        {
          "height": 0,
          "id": 156,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 128
        },
        {
          "height": 0,
          "id": 157,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 112
        },

        {
          "height": 0,
          "id": 158,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 144
        },
        {
          "height": 0,
          "id": 159,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 160
        },
        {
          "height": 0,
          "id": 160,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 188
        },
        {
          "height": 0,
          "id": 161,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 172
        },
        {
          "height": 0,
          "id": 162,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 136,
          "y": 204
        },
        {
          "height": 0,
          "id": 163,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 40
        },
        {
          "height": 0,
          "id": 164,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 68
        },
        {
          "height": 0,
          "id": 165,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 52
        },
        {
          "height": 0,
          "id": 166,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 84
        },
        {
          "height": 0,
          "id": 167,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 100
        },
        {
          "height": 0,
          "id": 168,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 128
        },
        {
          "height": 0,
          "id": 169,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 112
        },
        {
          "height": 0,
          "id": 170,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 144
        },
        {
          "height": 0,
          "id": 171,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 160
        },
        {
          "height": 0,
          "id": 172,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 188
        },
        {
          "height": 0,
          "id": 173,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 172
        },
        {
          "height": 0,
          "id": 174,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 152,
          "y": 204
        },
        {
          "height": 0,
          "id": 175,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 40
        },
        {
          "height": 0,
          "id": 176,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 68
        },
        {
          "height": 0,
          "id": 177,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 52
        },

        {
          "height": 0,
          "id": 178,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 84
        },
        {
          "height": 0,
          "id": 179,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 100
        },
        {
          "height": 0,
          "id": 180,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 128
        },
        {
          "height": 0,
          "id": 181,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 112
        },
        {
          "height": 0,
          "id": 182,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 144
        },
        {
          "height": 0,
          "id": 183,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 160
        },
        {
          "height": 0,
          "id": 184,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 188
        },
        {
          "height": 0,
          "id": 185,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 172
        },
        {
          "height": 0,
          "id": 186,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 168,
          "y": 204
        },
        {
          "height": 0,
          "id": 187,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 40
        },
        {
          "height": 0,
          "id": 188,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 68
        },
        {
          "height": 0,
          "id": 189,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 52
        },
        {
          "height": 0,
          "id": 190,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 84
        },
        {
          "height": 0,
          "id": 191,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 100
        },
        {
          "height": 0,
          "id": 192,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 128
        },
        {
          "height": 0,
          "id": 193,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 112
        },
        {
          "height": 0,
          "id": 194,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 144
        },
        {
          "height": 0,
          "id": 195,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 160
        },
        {
          "height": 0,
          "id": 196,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 188
        },
        {
          "height": 0,
          "id": 197,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 172
        },

        {
          "height": 0,
          "id": 198,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 184,
          "y": 204
        },
        {
          "height": 0,
          "id": 199,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 40
        },
        {
          "height": 0,
          "id": 200,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 68
        },
        {
          "height": 0,
          "id": 201,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 52
        },
        {
          "height": 0,
          "id": 202,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 84
        },
        {
          "height": 0,
          "id": 203,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 100
        },
        {
          "height": 0,
          "id": 204,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 128
        },
        {
          "height": 0,
          "id": 205,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 112
        },
        {
          "height": 0,
          "id": 206,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 144
        },
        {
          "height": 0,
          "id": 207,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 160
        },
        {
          "height": 0,
          "id": 208,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 188
        },
        {
          "height": 0,
          "id": 209,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 172
        },
        {
          "height": 0,
          "id": 210,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 200,
          "y": 204
        },
        {
          "height": 0,
          "id": 211,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 40
        },
        {
          "height": 0,
          "id": 212,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 68
        },
        {
          "height": 0,
          "id": 213,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 52
        },
        {
          "height": 0,
          "id": 214,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 84
        },
        {
          "height": 0,
          "id": 215,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 100
        },
        {
          "height": 0,
          "id": 216,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 128
        },
        {
          "height": 0,
          "id": 217,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 112
        },

        {
          "height": 0,
          "id": 218,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 144
        },
        {
          "height": 0,
          "id": 219,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 160
        },
        {
          "height": 0,
          "id": 220,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 188
        },
        {
          "height": 0,
          "id": 221,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 172
        },
        {
          "height": 0,
          "id": 222,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 216,
          "y": 204
        },
        {
          "height": 0,
          "id": 223,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 40
        },
        {
          "height": 0,
          "id": 224,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 68
        },
        {
          "height": 0,
          "id": 225,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 52
        },
        {
          "height": 0,
          "id": 226,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 84
        },
        {
          "height": 0,
          "id": 227,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 100
        },
        {
          "height": 0,
          "id": 228,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 128
        },
        {
          "height": 0,
          "id": 229,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 112
        },
        {
          "height": 0,
          "id": 230,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 144
        },
        {
          "height": 0,
          "id": 231,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 160
        },
        {
          "height": 0,
          "id": 232,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 188
        },
        {
          "height": 0,
          "id": 233,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 172
        },
        {
          "height": 0,
          "id": 234,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 232,
          "y": 204
        },
        {
          "height": 0,
          "id": 235,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 40
        },
        {
          "height": 0,
          "id": 236,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 68
        },
        {
          "height": 0,
          "id": 237,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 52
        },

        {
          "height": 0,
          "id": 238,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 84
        },
        {
          "height": 0,
          "id": 239,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 100
        },
        {
          "height": 0,
          "id": 240,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 128
        },
        {
          "height": 0,
          "id": 241,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 112
        },
        {
          "height": 0,
          "id": 242,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 144
        },
        {
          "height": 0,
          "id": 243,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 160
        },
        {
          "height": 0,
          "id": 244,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 188
        },
        {
          "height": 0,
          "id": 245,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 172
        },
        {
          "height": 0,
          "id": 246,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 248,
          "y": 204
        },
        {
          "height": 0,
          "id": 247,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 40
        },
        {
          "height": 0,
          "id": 248,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 68
        },
        {
          "height": 0,
          "id": 249,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 52
        },
        {
          "height": 0,
          "id": 250,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 84
        },
        {
          "height": 0,
          "id": 251,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 100
        },
        {
          "height": 0,
          "id": 252,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 128
        },
        {
          "height": 0,
          "id": 253,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 112
        },
        {
          "height": 0,
          "id": 254,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 144
        },
        {
          "height": 0,
          "id": 255,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 160
        },
        {
          "height": 0,
          "id": 256,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 188
        },
        {
          "height": 0,
          "id": 257,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 172
        },

        {
          "height": 0,
          "id": 258,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 264,
          "y": 204
        },
        {
          "height": 0,
          "id": 259,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 40
        },
        {
          "height": 0,
          "id": 260,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 68
        },
        {
          "height": 0,
          "id": 261,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 52
        },
        {
          "height": 0,
          "id": 262,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 84
        },
        {
          "height": 0,
          "id": 263,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 100
        },
        {
          "height": 0,
          "id": 264,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 128
        },
        {
          "height": 0,
          "id": 265,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 112
        },
        {
          "height": 0,
          "id": 266,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 144
        },
        {
          "height": 0,
          "id": 267,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 160
        },
        {
          "height": 0,
          "id": 268,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 188
        },
        {
          "height": 0,
          "id": 269,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 172
        },
        {
          "height": 0,
          "id": 270,
          "name": "RainbowTileOne",
          "point": true,
          "properties": [
            {
              "name": "Flavor",
              "type": "string",
              "value": "NonPhysicsSprite"
            },
            {
              "name": "Type",
              "type": "string",
              "value": "Lepton"
            }
          ],
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 280,
          "y": 204
        },
        {
          "height": 0,
          "id": 271,
          "name": "Default Spawn Point",
          "point": true,
          "rotation": 0,
          "type": "",
          "visible": true,
          "width": 0,
          "x": 161.755311762255,
          "y": 203.749479238995
        }
      ],
      "opacity": 1,
      "type": "objectgroup",
      "visible": true,
      "x": 0,
      "y": 0
    }
  ],
  "nextlayerid": 20,
  "nextobjectid": 272,
  "orientation": "orthogonal",
  "properties": [
    {
      "name": "EntranceMessage",
      "type": "string",
      "value": "a crystal cave with unusual properties"
    }
  ],
  "renderorder": "right-down",
  "tiledversion": "1.10.2",
  "tileheight": 16,
  "tilesets": [
    {
      "columns": 10,
      "firstgid": 1,
      "image": "../tileSets/Dungeon_Tileset-extruded.png",
      "imageheight": 180,
      "imagewidth": 180,
      "margin": 1,
      "name": "Dungeon_Tileset",
      "spacing": 2,
      "tilecount": 100,
      "tileheight": 16,
      "tilewidth": 16
    }
  ],
  "tilewidth": 16,
  "type": "map",
  "version": "1.10",
  "width": 20
}
