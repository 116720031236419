// Player bloomby has the comments in it, see it for help and examples.
// Note that you if you have a -move-stationary animation on a sprite,
// you do NOT need the other four. The goose has all five because it is a
// fallback default.
// You'd only want all 5 if your sprite really has 4 directions AND a separate stationary animation.

// I use texture atlases now, so there are typically no import statements here and no 'filename' keys on the sprite sheet list,
// HOWEVER, you can still add individual sprites directly here if you want to.
// Here is an example import statement:
// import myAmazingSpriteSheet from '../../../assets/myAmazingSpriteSheet.png';

// Then you need to add an object to the spriteSheetList array describing your sprite-sheet so we can use it.
// Just use the other entries as examples, but ALSO ADD a key called 'filename' where you use the import name that
// you used like this:
// {
//    type: 'other',
//    name: 'myAmazingSpriteSheet',
//    filename: myAmazingSpriteSheet,
//  ...
// Typically the 'name' and 'fileName' will be the same, but the 'fileName' key
// tells the code that this sprite needs to be loaded directly from the file.

const spriteSheetList = [
  {
    type: 'player',
    name: 'bloomby',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1, // How many frames to extract from the sheet. -1 means all
    animationFrameRate: 10,
    animations: [
      { keyName: 'move-left', start: 12, end: 14, zeroPad: 3, repeat: -1 },
      { keyName: 'move-right', start: 12, end: 14, zeroPad: 3, repeat: -1 },
      { keyName: 'move-back', start: 6, end: 11, zeroPad: 3, repeat: -1 },
      { keyName: 'move-front', start: 0, end: 5, zeroPad: 3, repeat: -1 },
    ],
    // physicsSize maps to sprite.setSize
    // physicsOffset maps to sprite.setOffset
    // If your Sprite has whitespace or just bits that can overlap, use
    // setSize (physicsSize) to make the physics object smaller than the sprite.
    // You can use the setSize (physicsSize) and setOffset to allow the character to overlap the
    // collision blocks slightly. This often makes the most sense for the head to overlap a bit so that "background" blocks (above player) seem more "background"
    // Also use the setSize (physicsSize) to allow the character to fit in the spaces it should, even if the sprite is too big for them.
    // Set physicsSize to 0 to have size === the sprite size.
    physicsSize: {
      // Remember where this starts if you muck with the offset below.
      // If you are having trouble, turn on the debugging on the intro/help screen.
      // This is RELATIVE TO SPRITE SIZE and then gets scaled, so the same numbers on two different
      // sized sprites will not produce the same results!
      // Using a multiplier can help keep it consistent between different sprites,
      // but remember different size sprites will still end up with different end results after multiplication.
      x: 12, // "Width" direction
      y: 14, // "Height" direction
    },
    // DELETE or COMMENT OUT the entire physicsOffset to ignore it and default to object center.
    // physicsOffset: {
    //   x: 25, // left/right aka in relation to width
    //   y: 25,
    // },
    displayWidth: 16,
    displayHeight: 16,
  },
  { type: 'other', name: 'litSmoke' },
  {
    type: 'other',
    name: 'ChairTwo',
    faces: 'right',
    frameWidth: 10,
    frameHeight: 12,
    endFrame: -1, // How many frames to extract from the sheet. -1 means all
    animationFrameRate: 0,
    animations: [
      { keyName: 'move-left', start: 2, end: 2, zeroPad: 3, repeat: -1 },
      { keyName: 'move-right', start: 2, end: 2, zeroPad: 3, repeat: -1 },
      { keyName: 'move-back', start: 1, end: 1, zeroPad: 3, repeat: -1 },
      { keyName: 'move-front', start: 0, end: 0, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 10,
      y: 12,
    },
    displayWidth: 10,
    displayHeight: 12,
  },
  {
    type: 'other',
    name: 'TableOne',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 10,
    physicsSize: {
      x: 16,
      y: 10,
    },
    displayWidth: 16,
    displayHeight: 10,
  },
  {
    type: 'other',
    name: 'Cabinet01',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 12,
    physicsSize: {
      x: 16,
      y: 12,
    },
    displayWidth: 16,
    displayHeight: 12,
  },
  {
    type: 'other',
    name: 'Cabinet02',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 15,
    physicsSize: {
      x: 16,
      y: 15,
    },
    displayWidth: 16,
    displayHeight: 15,
  },
  {
    type: 'other',
    name: 'Cabinet03',
    faces: 'right',
    frameWidth: 12,
    frameHeight: 12,
    physicsSize: {
      x: 12,
      y: 12,
    },
    displayWidth: 12,
    displayHeight: 12,
  },
  {
    type: 'other',
    name: 'Cabinet04',
    faces: 'right',
    frameWidth: 12,
    frameHeight: 15,
    physicsSize: {
      x: 12,
      y: 15,
    },
    displayWidth: 12,
    displayHeight: 15,
  },
  {
    type: 'other',
    name: 'Cabinet05',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 15,
    physicsSize: {
      x: 16,
      y: 15,
    },
    displayWidth: 16,
    displayHeight: 15,
  },
  {
    type: 'other',
    name: 'Cabinet06',
    faces: 'right',
    frameWidth: 12,
    frameHeight: 15,
    physicsSize: {
      x: 12,
      y: 15,
    },
    displayWidth: 12,
    displayHeight: 15,
  },
  {
    type: 'other',
    name: 'WickedSnailOne',
    faces: 'right',
    frameWidth: 32,
    frameHeight: 32,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 0, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 32,
      y: 32,
    },
    displayWidth: 32,
    displayHeight: 32,
  },
  {
    type: 'other',
    name: 'YellowKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'RedKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'TurquoiseKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'PurpleKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'PinkKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'OrangeKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'LightBlueKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'GreyKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'DarkBlueKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'BlueKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'BlackKey',
    faces: 'right',
    frameWidth: 9,
    frameHeight: 6,
    physicsSize: {
      x: 9,
      y: 6,
    },
    displayWidth: 9,
    displayHeight: 6,
  },
  {
    type: 'other',
    name: 'Whirl',
    faces: 'right',
    frameWidth: 45,
    frameHeight: 45,
    physicsSize: {
      x: 45,
      y: 45,
    },
    displayWidth: 45,
    displayHeight: 45,
    endFrame: -1,
    animationFrameRate: 10,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
  },
  {
    type: 'other',
    name: 'quasar',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1, // How many frames to extract from the sheet. -1 means all
    animationFrameRate: 20,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 17, zeroPad: 2, repeat: -1 },
    ],
    physicsCircle: {
      radius: 4,
    },
    physicsOffset: {
      x: 5,
      y: 5,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true, // For sprites that should rotate to any direction, instead of just the 4
  },
  {
    type: 'other',
    name: 'LandWater',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'right',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 7, zeroPad: 3, repeat: -1 },
    ],
    physicsOffset: {
      x: 2,
      y: 2,
    },
    physicsCircle: {
      radius: 6.5,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  // laserArrow
  {
    type: 'other',
    name: 'laserArrow',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1, // How many frames to extract from the sheet. -1 means all
    animationFrameRate: 20,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 9, zeroPad: 2, repeat: -1 },
    ],
    physicsCircle: {
      radius: 4,
    },
    physicsOffset: {
      x: 5,
      y: 5,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true, // For sprites that should rotate to any direction, instead of just the 4
  },
  {
    type: 'other',
    name: 'chest',
    frameWidth: 40,
    frameHeight: 43,
    endFrame: 3,
    faces: 'left',
    animationFrameRate: 2,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 2, zeroPad: 0, repeat: -1 },
    ],
    physicsSize: {
      x: 40 * 0.8,
      y: 43 * 0.8,
    },
    displayWidth: 40 * 0.8,
    displayHeight: 43 * 0.8,
  },
  {
    type: 'other',
    name: 'corpse',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: 1,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'RainbowTileOne',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 1,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 7, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 50,
      y: 50,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'writtenPaper',
    frameWidth: 14,
    frameHeight: 14,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 2,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 10, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 14,
      y: 14,
    },
    displayWidth: 14,
    displayHeight: 14,
  },
  {
    type: 'other',
    name: 'Fountain',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 5,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 10, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-RedKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-YellowKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-OrangeKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-GreyKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-BlueKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-BlackKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-PurpleKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-DarkBlueKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-LightBlueKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-PinkKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'Barricade-TurquoiseKey',
    faces: 'right',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'CircleTank',
    frameWidth: 16,
    frameHeight: 16,
    physicsCircle: {
      radius: 6.5,
    },
    physicsOffset: {
      x: 1,
      y: 1,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'CircleTankRed',
    frameWidth: 16,
    frameHeight: 16,
    physicsCircle: {
      radius: 6.5,
    },
    physicsOffset: {
      x: 1,
      y: 1,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'CircleTankOrange',
    frameWidth: 16,
    frameHeight: 16,
    physicsCircle: {
      radius: 6.5,
    },
    physicsOffset: {
      x: 1,
      y: 1,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'CircleTankGrey',
    frameWidth: 16,
    frameHeight: 16,
    physicsCircle: {
      radius: 6.5,
    },
    physicsOffset: {
      x: 1,
      y: 1,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'TankTurquoise',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 24,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'YellowTank',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 24,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'PurpleTank',
    frameWidth: 16,
    frameHeight: 16,
    physicsSize: {
      x: 16,
      y: 24,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'DarkBlueTank',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    animationFrameRate: 15,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 4, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'BlackTank',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 5, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'PurpleRunningRunes',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 5,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueTank',
    faces: 'left',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1, // How many frames to extract from the sheet. -1 means all
    animationFrameRate: 10,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 0, zeroPad: 3, repeat: -1 },
      { keyName: 'move-left', start: 2, end: 5, zeroPad: 3, repeat: -1 },
      { keyName: 'move-right', start: 2, end: 5, zeroPad: 3, repeat: -1 },
      { keyName: 'move-back', start: 0, end: 1, zeroPad: 3, repeat: -1 },
      { keyName: 'move-front', start: 0, end: 1, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 10,
      y: 12,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'PinkTank',
    faces: 'left',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1, // How many frames to extract from the sheet. -1 means all
    animationFrameRate: 10,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 0, zeroPad: 3, repeat: -1 },
      { keyName: 'move-left', start: 2, end: 5, zeroPad: 3, repeat: -1 },
      { keyName: 'move-right', start: 2, end: 5, zeroPad: 3, repeat: -1 },
      { keyName: 'move-back', start: 0, end: 1, zeroPad: 3, repeat: -1 },
      { keyName: 'move-front', start: 0, end: 1, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 10,
      y: 12,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'darkBlueTankSingle',
    frameWidth: 46,
    frameHeight: 38,
    physicsSize: {
      x: 46,
      y: 24,
    },
    displayWidth: 16,
    displayHeight: 16,
    rotatable: true,
  },
  {
    type: 'other',
    name: 'LetterM',
    frameWidth: 16,
    frameHeight: 16,
    physicsCircle: {
      radius: 6.5,
    },
    physicsOffset: {
      x: 1,
      y: 1,
    },
    physicsSize: {
      x: 0,
      y: 0,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlackWater',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 10,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 7, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 50,
      y: 50,
    },
    physicsOffset: {
      x: 22,
      y: 35,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlackFlowingDown',
    frameWidth: 32,
    frameHeight: 32,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 10,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 14, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 50,
      y: 50,
    },
    physicsOffset: {
      x: 22,
      y: 35,
    },
    displayWidth: 32,
    displayHeight: 32,
  },
  {
    type: 'other',
    name: 'SkullWink',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 8,
    animations: [
      {
        keyName: 'move-stationary',
        start: 0,
        end: 3,
        zeroPad: 3,
        repeat: -1,
        repeatDelay: 10000,
      },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWater',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 7, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterFlowingDown',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 7, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterNorthShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterSouthShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterNorthWestShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterNorthEastShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterSouthEastShoreCorner',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterSouthWestShoreCorner',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterNorthWestShoreCorner',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterSouthWestShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterSouthEastShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterWestShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'BlueWaterEastShore',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 7,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 15, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'MountainWallSparkleOne',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 1,
    animations: [
      {
        keyName: 'move-stationary',
        start: 0,
        end: 4,
        zeroPad: 3,
        repeat: -1,
        repeatDelay: 500,
      },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'MountainWallSparkleTwo',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 8,
    animations: [
      {
        keyName: 'move-stationary',
        start: 0,
        end: 8,
        zeroPad: 3,
        repeat: -1,
        repeatDelay: 10000,
      },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'SilverSparkleForTree',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 8,
    animations: [
      {
        keyName: 'move-stationary',
        start: 0,
        end: 8,
        zeroPad: 3,
        repeat: -1,
        repeatDelay: 10000,
      },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'GroundSparkleOne',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 1,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 5, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    type: 'other',
    name: 'GroundSparkleTwo',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: -1,
    faces: 'left',
    animationFrameRate: 1,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 5, zeroPad: 3, repeat: -1 },
    ],
    displayWidth: 16,
    displayHeight: 16,
  },
  {
    // Flaming Goose is the default sprite used whenever a requested sprite cannot be found,
    // so it is set up to fill a lot of roles, but also expected to never look great.
    type: 'other',
    name: 'flamingGoose',
    frameWidth: 16,
    frameHeight: 16,
    endFrame: 4,
    faces: 'left',
    animationFrameRate: 5,
    animations: [
      { keyName: 'move-stationary', start: 0, end: 3, zeroPad: 3, repeat: -1 },
      { keyName: 'move-left', start: 0, end: 3, zeroPad: 3, repeat: -1 },
      { keyName: 'move-right', start: 0, end: 3, zeroPad: 3, repeat: -1 },
      { keyName: 'move-back', start: 0, end: 3, zeroPad: 3, repeat: -1 },
      { keyName: 'move-front', start: 0, end: 3, zeroPad: 3, repeat: -1 },
    ],
    physicsSize: {
      x: 16,
      y: 16,
    },
    displayWidth: 16,
    displayHeight: 16,
  },
];

export default spriteSheetList;
